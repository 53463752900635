import React from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { Flex, Caption1, Caption2 } from '@components/atoms'
import styled from 'styled-components'
import { convertToPricingComma, insightConverseUnitEng } from '@utils/format'

const ToolTipBox = styled(Flex)`
  width: 280px;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 10px 0 rgba(36, 36, 36, 0.16);
  background-color: #ffffff;
  gap: 12px;
`

const COLORS = ['#66bc6a', '#ea4653', '#15861a']

const ArtistLikeInfoChart = ({ dailyListData }) => {
  return (
    <div
      style={{
        marginTop: '30px',
        borderRadius: '6px',
        boxShadow: '0 4px 10px 0 rgba(36,36,36,0.16)',
        backgroundColor: '#ffffff',
        padding: '20px',
        width: '100%',
        height: '500px',
      }}
    >
      {!dailyListData ? (
        <Flex
          style={{
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Caption1>No data</Caption1>
        </Flex>
      ) : (
        <ResponsiveContainer width="100%" height={500} debounce={300}>
          <LineChart
            data={dailyListData.reverse()}
            margin={{
              top: 0,
              right: 0,
              bottom: 50,
              left: 50,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis
              dataKey="date"
              tickFormatter={tick => {
                const strTick = `${tick}`
                // return `          ${strTick.slice(0, 4)}년 ${strTick.slice(4, 6)}월 ${strTick.slice(6, 8)}일          `
                return `          ${strTick.slice(2, 4)}.${strTick.slice(
                  4,
                  6,
                )}.${strTick.slice(6, 8)}          `
              }}
              allowDuplicatedCategory={false}
              padding={{ left: 40, right: 40 }}
            />
            <YAxis
              dataKey="melon"
              yAxisId="left"
              domain={['auto', 'auto']}
              tickFormatter={value => insightConverseUnitEng(value)}
            />
            <YAxis
              dataKey="youtube"
              yAxisId="right"
              orientation="right"
              domain={['auto', 'auto']}
              tickFormatter={value => insightConverseUnitEng(value)}
            />
            <YAxis
              dataKey="spotify"
              yAxisId="right"
              orientation="right"
              domain={['auto', 'auto']}
              tickFormatter={value => insightConverseUnitEng(value)}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              align="left"
              height="50px"
            />
            <Line
              dataKey="melon"
              yAxisId="left"
              name="멜론"
              type="monotone"
              stroke={COLORS[0]}
            />
            <Line
              dataKey="youtube"
              yAxisId="right"
              name="유튜브"
              type="monotone"
              stroke={COLORS[1]}
            />
            <Line
              dataKey="spotify"
              yAxisId="right"
              name="스포티파이"
              type="monotone"
              stroke={COLORS[2]}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}

export default ArtistLikeInfoChart

const CustomTooltip = ({ active, payload, label: _label }) => {
  const item = payload && payload[0] && payload[0].payload && payload[0].payload
  const label = `${_label}`

  if (active) {
    return (
      <ToolTipBox type="column">
        <Caption2
          type="Bold"
          align="left"
          color="#646464"
          style={{ fontSize: 10 }}
        >
          {`${label.slice(0, 4)}년 ${label.slice(4, 6)}월 ${label.slice(
            6,
            8,
          )}일`}
        </Caption2>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[0],
              }}
            />
            <Caption2>Melon</Caption2>
            <Caption2 style={{ fontSize: 10 }} color="#949494">
              일간 스트리밍
            </Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.melon)}명`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[1],
              }}
            />
            <Caption2>Youtube</Caption2>
            <Caption2 style={{ fontSize: 10 }} color="#949494">
              일간 스트리밍
            </Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.youtube)}명`}
          </Caption2>
        </Flex>
        <Flex justify="space-between">
          <Flex align="center" style={{ gap: 4 }}>
            <div
              style={{
                display: 'inline-block',
                width: 6,
                height: 6,
                borderRadius: '50%',
                backgroundColor: COLORS[2],
              }}
            />
            <Caption2>Spotify</Caption2>
            <Caption2 style={{ fontSize: 10 }} color="#949494">
              일간 스트리밍
            </Caption2>
          </Flex>
          <Caption2 align="left">
            {`${item && convertToPricingComma(item.spotify)}명`}
          </Caption2>
        </Flex>
      </ToolTipBox>
    )
  }
  return null
}
