import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
  Flex,
  Input,
  Text,
  TextArea,
  Caption2,
  Div,
  InvisibleInput,
} from '@components/atoms'
import {
  Modal,
  ModalContent,
  ModalFooter,
  LinkInput,
  FormLabel,
  FormGroup,
  SubmitBtn,
  SwitchBtn,
  FacebookLogo,
  InstagramLogo,
  YoutubeLogo,
  SoundcloudLogo,
  HomePageLogo,
  EmailLogo,
  EtcSocialLogo,
  XLogo,
  TiktokLogo,
  WeverseLogo,
} from '@components/molecules'
import { StreamingLogo } from '@components/molecules/icon/StramingButton'
import { ImageUploadForm } from '@components/organisms'
import { colors } from '@colors/'

import { useForm } from 'react-hook-form'
import { STORAGE_URL, SITE_LIST } from '@consts'

import AutoCompleteCompanyModel from './AutoCompleteCompanyModel'
import AutocompleteCompany from './AutocompleteCompany'

const Description = styled(TextArea)`
  margin: 0px 0px 30px 0px;
`

const FormList = styled(Flex)`
  padding: 0px 0px 16px 0px;
  justify-content: space-between;
  flex-wrap: wrap;
`

interface ArtistFormModalProps {
  show: Boolean;
  handleClose: Function;
  onCreateArtist: Function;
  onUpdateArtist: Function;
  userId: String;
  account: String;
  isUpdating: Boolean;
  defaultArtist: Object | null;
}

const SocialMediaLogo = ({
  type,
  style = {
    'margin-bottom': '2px',
    'margin-right': '12px',
    width: '40px',
    height: '40px',
  },
}) => {
  switch (type) {
    case 'facebook':
      return <FacebookLogo style={style} />
    case 'instagram':
      return <InstagramLogo style={style} />
    case 'youtube':
      return <YoutubeLogo style={style} />
    case 'soundcloud':
      return <SoundcloudLogo style={style} />
    case 'homepage':
      return <HomePageLogo style={style} />
    case 'email':
      return <EmailLogo style={style} />
    case 'twitter':
      return <XLogo style={style} />
    case 'etcSocial':
      return <EtcSocialLogo style={style} />
    case 'tiktok':
      return <TiktokLogo style={style} />
    case 'weverse':
      return <WeverseLogo style={style} />
    default:
      return <div />
  }
}

export const ArtistFormModal = ({
  show,
  handleClose,
  onCreateArtist,
  onUpdateArtist,
  userId,
  account,
  isUpdating,
  defaultArtist,
}: ArtistFormModalProps) => {
  const [allRequired, setAllRequired] = useState(false) // 모든 필수 입력사항 체크
  const [imageOriginalPath, setImageUrl] = useState() // 이미지 파일

  const [isDisplayInstagramData, setIsDisplayInstagramData] = useState(false) // SNS 수치 데이터 on/off
  const [isDisplayTwitterData, setIsDisplayTwitterData] = useState(false) // SNS 수치 데이터 on/off
  const [isDisplayYoutubeData, setIsDisplayYoutubeData] = useState(false) // SNS 수치 데이터 on/off
  const [isDisplaySoundcloudData, setIsDisplaySoundcloudData] = useState(false) // SNS 수치 데이터 on/off

  const { register, handleSubmit, errors, setValue, watch } = useForm()

  // 소속사 자동완성 데이터 수집
  const [agencyList, setAgencyList] = useState([])
  // const appendAgencyList = () => {
  //   setAgencyList([...agencyList, new AutoCompleteCompanyModel()])
  // }
  // const removeAgencyList = (index) => {
  //   const clonedAgencyList = [...agencyList]
  //   if (agencyList && index >= 0 && index < agencyList.length) {
  //     clonedAgencyList.splice(index, 1)
  //     setAgencyList(clonedAgencyList)
  //   }
  // }

  useEffect(() => {
    setAllRequired(false)
    setImageUrl(null)

    setAgencyList(
      (defaultArtist &&
        defaultArtist.agencyInfo && [
          new AutoCompleteCompanyModel(defaultArtist.agencyInfo),
        ]) || [new AutoCompleteCompanyModel()],
    )

    if (show && isUpdating && defaultArtist) {
      const defaultValues = { ...defaultArtist }
      setImageUrl(defaultValues && defaultValues.imageOriginalPath)
      setValue(
        Object.keys(defaultValues).map(key => ({
          [key]: defaultValues[key],
        })),
      )
      setIsDisplayInstagramData(
        defaultValues && defaultValues.isDisplayInstagramData,
      )
      setIsDisplayTwitterData(
        defaultValues && defaultValues.isDisplayTwitterData,
      )
      setIsDisplayYoutubeData(
        defaultValues && defaultValues.isDisplayYoutubeData,
      )
      setIsDisplaySoundcloudData(
        defaultValues && defaultValues.isDisplaySoundcloudData,
      )
    }
  }, [show, defaultArtist, isUpdating, setValue])

  const onSubmit = async data => {
    const {
      masterName,
      nameEng,
      description,
      //
      spotifyUrl,
      youtubeMusicUrl,
      melonUrl,
      genieUrl,
      bugsUrl,
      vibeUrl,
      floUrl,
      appleMusicUrl,
      amazonMusicUrl,
      tidalUrl,
      deezerUrl,
      jooxUrl,
      mymusicUrl,
      kkboxUrl,
      linejpUrl,
      linetwUrl,
      yandexUrl,
      nctUrl,
      zingUrl,
      anghmiUrl,

      isDisplaySpotifyUrl,
      isDisplayYoutubeMusicUrl,
      isDisplayMelonUrl,
      isDisplayGenieUrl,
      isDisplayBugsUrl,
      isDisplayVibeUrl,
      isDisplayFloUrl,
      isDisplayAppleMusicUrl,
      isDisplayAmazonMusicUrl,
      isDisplayTidalUrl,
      isDisplayDeezerUrl,
      isDisplayJooxUrl,
      isDisplayMymusicUrl,
      isDisplayKkboxUrl,
      isDisplayLinejpUrl,
      isDisplayLinetwUrl,
      isDisplayYandexUrl,
      isDisplayNctUrl,
      isDisplayZingUrl,
      isDisplayAnghmiUrl,
      //

      facebookUrl,
      instagramUrl,
      youtubeUrl,
      soundcloudUrl,
      homepageUrl,
      emailUrl,
      twitterUrl,
      etcSocialUrl,
      tiktokUrl,
      weverseUrl,
    } = data
    // if (!imageOriginalPath) {
    //   setAllRequired(true)
    //   return false
    // }

    if (
      spotifyUrl === '' &&
      youtubeMusicUrl === '' &&
      melonUrl === '' &&
      genieUrl === '' &&
      bugsUrl === '' &&
      vibeUrl === '' &&
      floUrl === '' &&
      appleMusicUrl === '' &&
      amazonMusicUrl === '' &&
      tidalUrl === '' &&
      deezerUrl === '' &&
      jooxUrl === '' &&
      mymusicUrl === '' &&
      kkboxUrl === '' &&
      linejpUrl === '' &&
      linetwUrl === '' &&
      yandexUrl === '' &&
      nctUrl === '' &&
      zingUrl === '' &&
      anghmiUrl === ''
    ) {
      // setAllRequired(true)
      // return false
    }

    if (
      facebookUrl === '' &&
      instagramUrl === '' &&
      youtubeUrl === '' &&
      soundcloudUrl === '' &&
      homepageUrl === '' &&
      emailUrl === '' &&
      twitterUrl === '' &&
      etcSocialUrl === '' &&
      tiktokUrl === '' &&
      weverseUrl === ''
    ) {
      // setAllRequired(true)
      // return false
    }

    // 입력 api
    const newArtistData = {
      // ...defaultArtist,
      _id: defaultArtist && defaultArtist._id,
      imageOriginalPath,
      name: masterName, // 대표자 명 수정 로직
      nameEng,
      description,

      spotifyUrl,
      youtubeMusicUrl,
      melonUrl,
      genieUrl,
      bugsUrl,
      vibeUrl,
      floUrl,
      appleMusicUrl,
      amazonMusicUrl,
      tidalUrl,
      deezerUrl,
      jooxUrl,
      mymusicUrl,
      kkboxUrl,
      linejpUrl,
      linetwUrl,
      yandexUrl,
      nctUrl,
      zingUrl,
      anghmiUrl,
      isDisplaySpotifyUrl: JSON.parse(isDisplaySpotifyUrl || 'false'),
      isDisplayYoutubeMusicUrl: JSON.parse(isDisplayYoutubeMusicUrl || 'false'),
      isDisplayMelonUrl: JSON.parse(isDisplayMelonUrl || 'false'),
      isDisplayGenieUrl: JSON.parse(isDisplayGenieUrl || 'false'),
      isDisplayBugsUrl: JSON.parse(isDisplayBugsUrl || 'false'),
      isDisplayVibeUrl: JSON.parse(isDisplayVibeUrl || 'false'),
      isDisplayFloUrl: JSON.parse(isDisplayFloUrl || 'false'),
      isDisplayAppleMusicUrl: JSON.parse(isDisplayAppleMusicUrl || 'false'),
      isDisplayAmazonMusicUrl: JSON.parse(isDisplayAmazonMusicUrl || 'false'),
      isDisplayTidalUrl: JSON.parse(isDisplayTidalUrl || 'false'),
      isDisplayDeezerUrl: JSON.parse(isDisplayDeezerUrl || 'false'),
      isDisplayJooxUrl: JSON.parse(isDisplayJooxUrl || 'false'),
      isDisplayMymusicUrl: JSON.parse(isDisplayMymusicUrl || 'false'),
      isDisplayKkboxUrl: JSON.parse(isDisplayKkboxUrl || 'false'),
      isDisplayLinejpUrl: JSON.parse(isDisplayLinejpUrl || 'false'),
      isDisplayLinetwUrl: JSON.parse(isDisplayLinetwUrl || 'false'),
      isDisplayYandexUrl: JSON.parse(isDisplayYandexUrl || 'false'),
      isDisplayNctUrl: JSON.parse(isDisplayNctUrl || 'false'),
      isDisplayZingUrl: JSON.parse(isDisplayZingUrl || 'false'),
      isDisplayAnghmiUrl: JSON.parse(isDisplayAnghmiUrl || 'false'),

      facebookUrl,
      instagramUrl,
      youtubeUrl,
      soundcloudUrl,
      homepageUrl,
      emailUrl,
      twitterUrl,
      etcSocialUrl,
      tiktokUrl,
      weverseUrl,

      uniqueName: account && account,
      userId,
      isDisplayInstagramData,
      isDisplayTwitterData,
      isDisplayYoutubeData,
      isDisplaySoundcloudData,

      isExistsSubdomainNotificaion:
        (defaultArtist &&
          defaultArtist.isExistsSubdomainNotificaion &&
          defaultArtist.isExistsSubdomainNotificaion) ||
        false,

      agencyId: agencyList[0].toIdObj(),
    }

    if (isUpdating) {
      await onUpdateArtist(newArtistData)
    } else {
      await onCreateArtist(newArtistData)
    }
    handleClose()
  }

  return (
    <Modal
      style={{ minHeight: '1035px', width: '1720px' }}
      show={show}
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <FormGroup style={{ width: '400px' }}>
            <FormLabel required>아티스트 명</FormLabel>
            <Input
              style={{ margin: '0px 0px 16px 0px' }}
              placeholder="아티스트 명을 입력해주세요."
              name="masterName"
              ref={register({ required: true })}
            />
            <FormLabel>아티스트 명(해외)</FormLabel>
            <Input
              style={{ margin: '0px 0px 16px 0px' }}
              placeholder="아티스트 해외명을 입력해주세요."
              name="nameEng"
              ref={register()}
            />
            {/* 로직 붙여야함 */}
            <ImageUploadForm
              label={'+ 아티스트 이미지'}
              imageOriginalPath={
                imageOriginalPath && STORAGE_URL + imageOriginalPath
              }
              setImageUrl={setImageUrl}
            />
            <FormLabel>소속사</FormLabel>
            <Flex>
              {agencyList.map((item, index) => {
                return (
                  <div key={`agency-${index}`} style={{ position: 'relative' }}>
                    <AutocompleteCompany
                      value={agencyList[index]}
                      setCompany={autocompleteCompany => {
                        const clonedAgencyList = [...agencyList]
                        Object.keys(clonedAgencyList[index]).forEach(key => {
                          clonedAgencyList[index][key] =
                            autocompleteCompany[key]
                        })
                        setAgencyList(clonedAgencyList)
                      }}
                      // isDisabled={index === 0}
                      index={index}
                      isDisabledRemoveBtn={index === 0}
                    // onClickRemove={() => removeAgencyList(index)}
                    />
                  </div>
                )
              })}
            </Flex>
          </FormGroup>
          <FormGroup style={{ width: '1170px' }}>
            <FormLabel>설명</FormLabel>
            <Description
              placeholder="설명을 입력해주세요."
              name="description"
              ref={register()}
            />

            <FormLabel>음악 서비스</FormLabel>
            <FormList>
              {SITE_LIST.map(site => {
                return (
                  <Div
                    key={site.name}
                    width="565px"
                    display="flex"
                    alignItems="center"
                  >
                    <StreamingLogo
                      type={site.name}
                      style={{
                        width: '40px',
                        height: '40px',
                        border: '1px solid #eaeaea',
                        'border-radius': '30%',
                        'margin-right': '20px',
                      }}
                    />
                    <InvisibleInput ref={register()} name={site.displayName} />
                    <SwitchBtn
                      id={site.displayName}
                      isSwitchOn={JSON.parse(
                        watch()[`${site.displayName}`] || 'false',
                      )}
                      handleSwitch={e => {
                        setValue(
                          `${site.displayName}`,
                          e?.currentTarget?.checked,
                        )
                      }}
                    />
                    <LinkInput
                      placeholder={site.text}
                      name={site.name}
                      ref={register()}
                      onChange={e => {
                        if (e?.currentTarget?.value !== '')
                          setValue(`${site.displayName}`, true)
                      }}
                    />
                  </Div>
                )
              })}
            </FormList>

            <FormLabel>소셜미디어</FormLabel>
            <FormList>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'homepage'} />
                <LinkInput
                  placeholder={'홈페이지'}
                  name={'homepageUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'instagram'} />
                <LinkInput
                  placeholder={'인스타그램'}
                  name={'instagramUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'twitter'} />
                <LinkInput
                  placeholder={'X(트위터)'}
                  name={'twitterUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'weverse'} />
                <LinkInput
                  placeholder={'위버스'}
                  name={'weverseUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'youtube'} />
                <LinkInput
                  placeholder={'유튜브'}
                  name={'youtubeUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'soundcloud'} />
                <LinkInput
                  placeholder={'사운드 클라우드'}
                  name={'soundcloudUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'facebook'} />
                <LinkInput
                  placeholder={'페이스북'}
                  name={'facebookUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'tiktok'} />
                <LinkInput
                  placeholder={'틱톡'}
                  name={'tiktokUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'email'} />
                <LinkInput
                  placeholder={'메일'}
                  name={'emailUrl'}
                  ref={register()}
                />
              </Div>
              <Div width="565px" display="flex" alignItems="center">
                <SocialMediaLogo type={'etcSocial'} />
                <LinkInput
                  placeholder={'기타'}
                  name={'etcSocialUrl'}
                  ref={register()}
                />
              </Div>
            </FormList>
          </FormGroup>
        </ModalContent>
        <ModalFooter>
          {allRequired || errors.name ? (
            <Text
              type="Medium"
              size="14px"
              style={{ paddingBottom: '25px' }}
              color="#ea4653"
            >
              필수 입력 사항을 모두 입력해주세요.
            </Text>
          ) : (
            false
          )}
          {isUpdating ? (
            <SubmitBtn type="submit">정보 수정</SubmitBtn>
          ) : (
            <SubmitBtn type="submit">정보 저장</SubmitBtn>
          )}
        </ModalFooter>
      </form>
    </Modal>
  )
}
