import React, { useEffect, useState } from 'react'
import {
  RootTemplate,
  InsightTrackYoutubeTemplate,
} from '@components/templates'
import { observer } from 'mobx-react'
import { Redirect, useLocation, useParams } from 'react-router-dom'
import { useStore } from '@utils/hooks'
import qs from 'qs'
import QueryString from 'qs'

interface Props {
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const InsightTrackYoutubePage = ({ location, logout, user, match }: Props) => {
  const { authStore, trendStore, artistStore } = useStore()
  const params = useParams()

  const fetchArtistTrackTrend = trendStore.fetchArtistTrackTrend || (() => { })
  const fetchCompanyTrackTrend = trendStore.fetchCompanyTrackTrend || (() => { })

  const { currentUser } = authStore
  const artist = artistStore?.artistDetail || null
  const { artistTrackTrend, companyTrackTrend } = trendStore

  // const location = useLocation()
  // const query = QueryString.parse(location.search, {
  //   ignoreQueryPrefix: true,
  //   // 문자열 맨 앞의 ?를 생력
  // })
  const isCompany = (location?.pathname || '')?.startsWith('/insight/company/')
  const isCompanyData = currentUser?.type === 'company' && isCompany
  const trackTrend = isCompanyData ? companyTrackTrend : artistTrackTrend

  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  useEffect(() => {
    if (isCompanyData) {
    }
    else {
      if (!artistStore.artistDetail) {
        artistStore.fetchArtistDetail(user && user['artistId'])
      } else {
        artistStore.fetchArtistDetail(artistStore.artistDetail._id)
      }
    }
  }, [user, artistStore, match, periodType])

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })
    if (params?.id) {
      const __periodDays =
        periodType.value === 'weekly'
          ? 7
          : periodType.value === 'monthly'
            ? 28
            : periodType.value === 'daily'
              ? 1
              : periodType.value === 'all'
                ? 365 * 10
                : null

      if (isCompanyData) {
        if (currentUser?.companyId) {
          fetchCompanyTrackTrend(currentUser?.companyId, params.id, { __periodDays })
        }
      }
      else {
        if (query.artistId && query.artistId !== 'null') {
          fetchArtistTrackTrend(query.artistId, params.id, { __periodDays })
        } else {
          // fetchArtistTrackTrend(user && user['artistId'], params.id, { __periodDays })
          fetchArtistTrackTrend(artist?._id, params.id, { __periodDays })
        }
      }
    }
  }, [
    user,
    artist,
    params,
    artistStore,
    fetchArtistTrackTrend,
    periodType,
    location,
  ])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {/* {!(user && user['artistId']) && <Redirect to="/" />} */}
        <InsightTrackYoutubeTemplate
          artist={artist}
          artistTrackTrend={trackTrend}
          periodType={periodType}
          setPeriodType={setPeriodType}
        />
      </RootTemplate>
    </>
  )
}

export default observer(InsightTrackYoutubePage)
