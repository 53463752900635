import React from 'react'
import styled from 'styled-components'
import {
  Flex,
  Text,
  H3,
  H5,
  H4,
  Container,
  H6,
  Caption1,
} from '@components/atoms'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  TrackYoutubeListenChart,
  DailyTrackLikeTableChart,
  StreamingListenLikeCard,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma, dateYMD } from '@utils/format'
import { STORAGE_URL } from '@consts/'
import { useHistory } from 'react-router-dom'
import { plamImg, youtubeMusicImg, youtubeSnsImg } from '@images/index'
import DailyTrackYoutubeTableChart from '@components/organisms/Chart/DailyTrackYoutubeTableChart'
import YoutubeVideoViewCard from '@components/organisms/Card/YoutubeVideoViewCard'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const MusicInfo = styled(Flex)`
  height: 160px;
  margin: 0 0 30px 0;

  img {
    width: 160px;
    height: 160px;
    border-radius: 6px;
    margin-right: 30px;
  }
`

export const InsightTrackYoutubeTemplate = ({
  artist,
  artistTrackTrend,
  periodType,
  setPeriodType,
}) => {
  const history = useHistory()

  const albumInfo = artistTrackTrend?.albumInfo || null
  const likeCountInfo = artistTrackTrend?.likeCountInfo || null
  const listenerInfo = artistTrackTrend?.listenerInfo || null

  const youtubeDailyList = artistTrackTrend?.youtubeDailyList || null
  const youtubeVideoInfoList = artistTrackTrend?.youtubeVideoInfoList || null

  console.log('listenerInfo', listenerInfo)
  console.log('likeCountInfo', likeCountInfo)

  return (
    <Container>
      <Header>
        <Text type="Medium" size="18px" style={{ marginTop: '10px' }}>
          Youtube
        </Text>
        <Text
          type="Medium"
          color="#949494"
          size="14px"
          style={{ margin: '10px 10px 0 0' }}
        >
          {artist && artist.nowLikeCrawling
            ? '정보를 업데이트 중 입니다.'
            : `${artistTrackTrend &&
                dateYMDMM(artistTrackTrend.trendDataAddedAt)} 업데이트`}
        </Text>
      </Header>
      <Flex
        style={{
          alignItems: 'center',
          gap: 16,
          marginTop: 54,
          paddingBottom: 30,
          borderBottom: '1px solid #f0f0f0',
        }}
      >
        <img src={youtubeSnsImg} width={48} height={48} alt="youtube" />
        <H3>Youtube</H3>
      </Flex>
      <Content>
        <Flex
          style={{
            justifyContent: 'space-between',
            paddingBottom: 30,
            borderBottom: '1px solid #f0f0f0',
          }}
        >
          <Flex type="column">
            <MusicInfo>
              <img
                src={STORAGE_URL + albumInfo?.image512Path}
                alt="album_img"
              />
              <Flex type="column">
                <H4 align="left" color="#949494">
                  {albumInfo?.title}
                </H4>
                <H3 align="left" style={{ width: '880px' }}>
                  {artistTrackTrend?.title}
                </H3>
                <Flex style={{ marginTop: 4 }}>
                  <H6>{`권리사 : ${albumInfo?.planningCompanyList[0]?.name}`}</H6>
                  <H6>
                    &nbsp;&nbsp;&nbsp;
                    {`유통사 : ${albumInfo?.releaseCompanyList[0]?.name}`}
                  </H6>
                </Flex>
              </Flex>
            </MusicInfo>
            <Flex
              style={{
                gap: 8,
              }}
            >
              <Flex
                style={{
                  padding: '8px 16px',
                  border: '1px solid #d4d4d4',
                  borderRadius: 6,
                  cursor: 'pointer',
                  gap: 6,
                  alignItems: 'center',
                }}
                onClick={() => window.open(artistTrackTrend?.youtubeMusicUrl)}
              >
                <img src={youtubeMusicImg} width={20} height={20} />
                <Caption1>Youtube Music</Caption1>
              </Flex>
              <Flex
                style={{
                  padding: '8px 16px',
                  border: '1px solid #d4d4d4',
                  borderRadius: 6,
                  cursor: 'pointer',
                  gap: 6,
                  alignItems: 'center',
                }}
                onClick={() =>
                  window.open(`https://plam.in/track/${artistTrackTrend?._id}`)
                }
              >
                <img src={plamImg} width={20} height={20} />
                <Caption1>PLAM</Caption1>
              </Flex>
            </Flex>
          </Flex>
          <Flex type="column" style={{ width: '258px', margin: '40px 0 0 0' }}>
            <ChartDivisionBox
              options={[
                { label: '전체', value: 'all' },
                { label: '지난 1일', value: 'daily' },
                { label: '지난 7일', value: 'weekly' },
                { label: '지난 28일', value: 'monthly' },
              ]}
              value={periodType}
              onChange={value => setPeriodType(value)}
            />
          </Flex>
        </Flex>
        <SectionTitle style={{ marginTop: 30, gap: 6 }}>
          <Flex style={{ alignItems: 'flex-end', gap: 10 }}>
            <H5 align="left" color="#949494">
              Total Daily
            </H5>
            {artistTrackTrend?.trendDataAddedAt && (
              <H6 color="#949494">
                *{dateYMD(artistTrackTrend.trendDataAddedAt)}
              </H6>
            )}
          </Flex>
          <Flex style={{ gap: '20px', alignItems: 'flex-start' }}>
            <Flex align="center">
              <H4 type="bold">
                {`${convertToPricingComma(
                  listenerInfo?.today?.youtube || 0,
                )} Listen`}
              </H4>
              <IncreaseTextBox
                data={listenerInfo?.increaseValue?.youtube || 0}
                style={{ marginLeft: '12px' }}
              />
            </Flex>
            <Flex type="column" align="flex-start">
              <Flex align="center">
                <H4 type="bold">
                  {`${convertToPricingComma(
                    listenerInfo?.today?.youtubeMusic,
                  ) || 0} Listen`}
                </H4>
                <IncreaseTextBox
                  data={listenerInfo?.increaseValue?.youtubeMusic || 0}
                  style={{ marginLeft: '12px' }}
                />
              </Flex>
              <Caption1 color={'#949494'}>*Y.T Music</Caption1>
            </Flex>
            <Flex type="column" align="flex-start">
              <Flex align="center">
                <H4 type="bold">
                  {`${convertToPricingComma(
                    listenerInfo?.today?.youtubeVideo,
                  ) || 0} View`}
                </H4>
                <IncreaseTextBox
                  data={listenerInfo?.increaseValue?.youtubeVideo || 0}
                  style={{ marginLeft: '12px' }}
                />
              </Flex>
              <Caption1 color={'#949494'}>*Youtube</Caption1>
            </Flex>
            <Flex align="center">
              <H4 type="bold">
                {`${convertToPricingComma(likeCountInfo?.today?.total) ||
                  0} Like`}
              </H4>
              <IncreaseTextBox
                data={likeCountInfo?.increaseValue?.total || 0}
                style={{ marginLeft: '12px' }}
              />
            </Flex>
          </Flex>
        </SectionTitle>
        <Flex
          style={{
            gap: 20,
            alignItems: 'flex-start',
            paddingBottom: 50,
            borderBottom: '1px solid #f0f0f0',
          }}
        >
          <TrackYoutubeListenChart dailyListData={youtubeDailyList || []} />
          <DailyTrackYoutubeTableChart data={youtubeDailyList} />
        </Flex>
        <Flex type="column" style={{ padding: '30px 0' }}>
          <H5
            align="left"
            style={{ paddingBottom: 16, borderBottom: '1px solid #242424' }}
          >
            Youtube 조회수
          </H5>
          {youtubeVideoInfoList?.map(info => (
            <YoutubeVideoViewCard item={info} key={info.index || 0} />
          ))}
        </Flex>
      </Content>
    </Container>
  )
}
