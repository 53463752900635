/* eslint-disable import/no-unresolved */
import { observer, inject } from 'mobx-react'
import React, { useEffect, useState, useMemo } from 'react'
import { RootTemplate } from '@components/templates'
import qs from 'qs'

import 'react-data-grid/lib/styles.css'
import { Spin } from 'antd'
import moment from 'moment'
import {
  TopContainer,
  TotalInfoContainer,
  TotalInfoMarginBox,
  BottomContainer,
  SheetContainer,
  TitleSubContainer,
  LoadMusicData,
} from './components/companyRoyaltyStatement/styledComponents'

import { AdjustmentTypeHeader } from './components/AdjustmentTypeHeader'
import { TotalInfo } from './components/TotalInfo'
import { RoyaltyStatementContainer } from './components/RoyaltyStatementContainer'
import { WritingEmpty } from './components/listPage/WritingEmpty'
import { CompanySelector } from './components/CompanySelector'
import { getColumns } from './components/companyRoyaltyStatement/royaltyStatementColumns'
import CompanyRoyaltyStatementTable from './components/companyRoyaltyStatement/CompanyRoyaltyStatementTable'
import ClosingAdjustmentModal from './components/companyRoyaltyStatement/ClosingAdjustmentModal'
import { TagButtonContainer } from './components/TagButtonContainer'
import DeleteConfirmModal from './components/companyRoyaltyStatement/DeleteConfirmModal'
import AddAdjustmentTypeModal from './components/companyRoyaltyStatement/AddAdjustmentTypeModal'
import TopHeader from './components/companyRoyaltyStatement/TopHeader'
import StartDatePicker from './components/companyRoyaltyStatement/StartDatePicker'
import AddRowButton from './components/companyRoyaltyStatement/AddRowButton'
import AdjustmentTitleInput from './components/companyRoyaltyStatement/AdjustmentTitleInput'
import TitleSavingStatus from './components/companyRoyaltyStatement/\bTitleWithSavingStatus'
import ReadOnlyInfoText from './components/companyRoyaltyStatement/ReadOnlyInfoText'
import LoadMusicDataModal from './components/companyRoyaltyStatement/LoadMusicDataModal'

moment.locale('ko')

const CompanyRoyaltyStatementPage = ({
  location,
  authStore,
  artistStore,
  user,
  adjustmentStore,
  adjustmentContractStore,
  adjustmentDetailStore,
  adjustmentTypeStore,
  settlementStore,
  history,
}) => {
  const [currentArtistId, setCurrentArtistId] = useState(null)
  const readOnly = user?.type === 'artist'

  const artist = artistStore.artistDetail
  const [allowViewArtistList, setAllowViewArtistList] = useState([]) // 정산서 조회 권한이 있는 아티스트 리스트
  const logout = authStore?.logout || (() => {})

  const [isSaving, setIsSaving] = useState(false)
  const [showSaving, setShowSaving] = useState(false)
  const [lastSavedTime, setLastSavedTime] = useState('')

  const [isOpenStartDatePicker, setIsOpenStartDatePicker] = useState('')

  const [selectedTypes, setSelectedTypes] = useState([])

  // type 추가 모달 변수
  const [newAdjustmentType, setNewAdjustmentType] = useState('')

  // 아이템 삭제 모달 변수
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
  const [handleDelete, setHandleDelete] = useState(() => () => {})

  // 정산 마감 모달 변수
  const [isClosingModalVisible, setIsClosingModalVisible] = useState(false)

  // 정산 내역 불러오기 모달 변수
  const [
    isLoadMusicDataModalVisible,
    setIsLoadMusicDataModalVisible,
  ] = useState(false)

  const [isLoading, setIsLoading] = useState(true)

  // company selector: selectedCompanyId는 store에서 관리하는데 useEffect trigger render용 변수
  const { selectedCompanyId, setSelectedCompanyId } = adjustmentContractStore

  const [companyList, setCompanyList] = useState([])

  /** 정산 구분 시작 */
  const [
    isAddAdjustmentTypeModalVisible,
    setIsAddAdjustmentTypeModalVisible,
  ] = useState(false)
  const [
    handleAddAdjustmentType,
    setHandleAddAdjustmentType,
  ] = useState(() => () => {})
  // 구분 없음을 맨 앞으로 빼기 위해 재정렬
  const adjustmentTypeList = adjustmentStore?.writingAdjustment?.amountInfo?.amountDetailList?.map(
    detail => detail.type,
  )

  const currAdjustmentTypes = [
    ...(adjustmentTypeList
      ? adjustmentTypeList
        .filter(item => item !== null)
        .map(item => ({
          value: item,
          label: item,
        }))
      : []),
  ]

  const allAdjustmentTypeList = adjustmentTypeStore.adjustmentTypeList || []

  const handleClickAdjustmentType = buttonValue => {
    if (buttonValue === '전체') {
      setSelectedTypes([])
      return
    }

    const selectedIndex = selectedTypes.indexOf(buttonValue)

    if (selectedIndex === -1) {
      setSelectedTypes([...selectedTypes, buttonValue])
    }
    else {
      setSelectedTypes(selectedTypes.filter(value => value !== buttonValue))
    }
  }
  /** 정산 구분 끝 */

  /** 이월 시작 */
  const [isUpdatingCheckbox, setIsUpdatingCheckbox] = useState(false) // 이월 체크박스 async 스피너 상태
  const [updatingId, setUpdatingId] = useState(null) // async 스피너 상태 체크박스 item 구분용

  const onCarryOverChange = async ({ typeId, isCreate }) => {
    if (!typeId) return

    setIsUpdatingCheckbox(true)
    setUpdatingId(typeId)
    if (isCreate) {
      const res = await adjustmentStore.createAdjustmentTypeCarryOver({
        adjustmentId: adjustmentStore?.writingAdjustment?._id,
        adjustmentTypeId: typeId,
      })
    }
    else {
      const res = await adjustmentStore.deleteAdjustmentTypeCarryOver({
        adjustmentId: adjustmentStore?.writingAdjustment?._id,
        adjustmentTypeId: typeId,
      })
    }

    await adjustmentStore.fetchWritingAdjustment({
      artistId: query.artistId,
      companyId: user.companyId,
    })
    setIsUpdatingCheckbox(false)
    setUpdatingId(null)
  }
  /** 이월 끝 */

  /** 타입 추가 모달 start */
  const handleCancelAddAdjustmentTypeModal = () => {
    setIsAddAdjustmentTypeModalVisible(false)
  }
  /** 타입 추가 모달 end */

  /** 정산서 마감 모달 start */
  const showClosingAdjustmentModal = () => {
    if (
      adjustmentStore?.writingAdjustment?.amountInfo?.adjustmentContractList?.find(
        item => item.artistRatio === null || item.artistRatio === null,
      ) !== undefined
    ) {
      alert('정산 비율이 입력되지 않은 계약이 있습니다.')
      return
    }
    if (isSaving) {
      alert(
        '정산 내역 저장중입니다.\n잠시후에 다시 시도하시거나 새로고침후 시도해주세요.',
      )
    }

    if (!adjustmentStore?.writingAdjustment?.title) {
      alert('정산서 제목을 입력해주세요.')
      return
    }

    if (!adjustmentStore?.writingAdjustment?.adjustmentStartDate) {
      alert('정산 시작일을 입력해주세요.')
      setIsOpenStartDatePicker(true)
      return
    }

    // 정산서 날짜 입력 validation 추가
    const hasNullDate = adjustmentDetailStore.adjustmentDetailList.find(
      item => item.date === null,
    )
    if (hasNullDate) {
      alert('날짜가 입력되지 않은 정산내역이 있습니다.')
      return
    }

    // 정산서 금액 입력 validation 추가
    const hasNullAmount = adjustmentDetailStore.adjustmentDetailList.find(
      item => item.amount === null || item.amount === '',
    )

    if (hasNullAmount) {
      alert('금액이 입력되지 않은 정산내역이 있습니다.')
      return
    }

    // 정산서 금액 입력 validation 추가
    const hasNullDescription = adjustmentDetailStore.adjustmentDetailList.find(
      item => item.description === null || item.description.trim() === '',
    )
    if (hasNullDescription) {
      alert('내용이 입력되지 않은 정산내역이 있습니다.')
      return
    }

    setIsClosingModalVisible(true)
  }

  const handleCloseClosingModal = () => {
    setIsClosingModalVisible(false)
  }

  const handleConfirmClosingModal = async selectedDate => {
    // 마감 데이터 서버 푸시하고, 정산서 리스트로 본내기
    const params = {
      adjustmentConfirmDate: moment(selectedDate).format('YYYYMMDD'),
      adjustmentTitle: adjustmentStore.writingAdjustment.title,
      isConfirmed: true,
    }

    const res = await adjustmentStore.updateAdjustment({ params })

    setIsClosingModalVisible(false)

    if (res?.adjustment?._id) {
      history.push(
        `/statement-list/closed?artistId=${currentArtistId}&adjustmentId=${res?.adjustment?._id}`,
      )
    }
  }
  /** 정산서 마감 모달 end */

  const savingStart = () => {
    setLastSavedTime('')
    setShowSaving(true)
    setIsSaving(true)
  }

  const savingFinish = () => {
    setIsSaving(false)
    // 저장됨 텍스트 2초 후에 사라지게
    setTimeout(() => {
      setShowSaving(false)
      setLastSavedTime(moment())
    }, 2000)
  }
  // cell update
  const updateCell = ({ cell, update }) => {
    const _row = adjustmentDetailStore.adjustmentDetailList[cell.row.index]
    _row[cell.column.id] = update

    if (cell.column.id === 'amountAbs') {
      _row.amount = _row.tag === '지출' ? -update : update
    }

    adjustmentDetailStore.adjustmentDetailList = [
      ...adjustmentDetailStore.adjustmentDetailList,
    ]
  }

  // update detail to server
  const updateAdjustmentDetail = async index => {
    const { writingAdjustment } = adjustmentStore
    const _row = adjustmentDetailStore.adjustmentDetailList[index]
    const params = {
      sortIndex: _row.sortIndex,
      description: _row.description,
      type: _row.type,
      date: _row.date,
      tag: _row.tag,
      amount: _row.tag === '지출' ? -_row.amountAbs : _row.amountAbs,
      fileList: _row.fileList,
      // adjustmentContractId: _row.adjustmentContractId || null,
    }

    const res = await adjustmentDetailStore.updateAdjustmentDetail({
      adjustmentId: writingAdjustment._id,
      adjustmentDetailId: adjustmentDetailStore.adjustmentDetailList[index]._id,
      params,
    })
    await adjustmentStore.fetchWritingAdjustment({
      artistId: query.artistId,
      companyId: user.companyId,
    })
  }

  const updateAdjustment = async ({ params } = {}) => {
    if (user.type !== 'company') return
    if (readOnly) return
    savingStart()
    const res = await adjustmentStore.updateAdjustment({ params })
    savingFinish()
  }

  const openFile = file => {
    window.open(file.path)
  }

  const handleDeleteFile = (cell, index) => {
    if (readOnly) return
    savingStart()
    const cellFiles =
      adjustmentDetailStore.adjustmentDetailList[cell.row.index][cell.column.id]
    // data의 fileList에서 해당 인덱스 삭제
    updateCell({
      cell,
      update: [...cellFiles.slice(0, index), ...cellFiles.slice(index + 1)],
    })
    updateAdjustmentDetail(cell.row.index)

    savingFinish()
  }

  const changeRow = ({ draggingRow, hoveredRow }) => {
    savingStart()
    const dragIndex =
      adjustmentDetailStore.adjustmentDetailList[draggingRow.index]['sortIndex']
    const hoverIndex =
      adjustmentDetailStore.adjustmentDetailList[hoveredRow.index]['sortIndex']
    const promises = []
    // drag한 행의 순서를 hover된 행의 순서로 변경
    adjustmentDetailStore.adjustmentDetailList[draggingRow.index][
      'sortIndex'
    ] = hoverIndex
    promises.push(updateAdjustmentDetail(draggingRow.index))
    // 나머지 행들 순서 정리
    if (dragIndex > hoverIndex) {
      for (let i = hoveredRow.index; i < draggingRow.index; i++) {
        adjustmentDetailStore.adjustmentDetailList[i]['sortIndex'] += 1
        promises.push(updateAdjustmentDetail(i))
      }
    }
    else {
      for (let i = draggingRow.index + 1; i <= hoveredRow.index; i++) {
        adjustmentDetailStore.adjustmentDetailList[i]['sortIndex'] -= 1
        promises.push(updateAdjustmentDetail(i))
      }
    }
    adjustmentDetailStore.adjustmentDetailList = [
      ...adjustmentDetailStore.adjustmentDetailList
        .slice()
        .sort((a, b) => a['sortIndex'] - b['sortIndex']),
    ]
    Promise.all(promises).then(() => savingFinish())
  }

  const addRow = async () => {
    if (user.type !== 'company') return

    savingStart()
    const { writingAdjustment } = adjustmentStore

    const resList = []

    const defaultTypeId = adjustmentTypeStore.adjustmentTypeList.find(
      type => type.isDefault === true,
    )?._id

    for (let i = 0; i < 5; i++) {
      // eslint-disable-next-line no-await-in-loop
      const res = await adjustmentDetailStore.createAdjustmentDetail({
        adjustmentId: writingAdjustment._id,
        params: {
          typeId: defaultTypeId,
          tag: '수입',
        },
      })

      resList.push(res)
    }

    if (resList) {
      adjustmentDetailStore.adjustmentDetailList = [
        ...adjustmentDetailStore.adjustmentDetailList,
        ...resList,
      ]
      await adjustmentStore.fetchWritingAdjustment({
        artistId: query.artistId,
        companyId: user.companyId,
      })
      savingFinish()
    }
  }

  const showDeleteModal = cell => {
    if (user.type !== 'company') return

    setHandleDelete(() => async () => {
      savingStart()
      const _row = adjustmentDetailStore.adjustmentDetailList[cell.row.index]
      const res = await adjustmentDetailStore.deleteAdjustmentDetail({
        adjustmentId: adjustmentStore.writingAdjustment._id,
        adjustmentDetailId: _row._id,
      })

      if (res) {
        const temp = adjustmentDetailStore.adjustmentDetailList.filter(
          (item, index) => index !== cell.row.index,
        )
        adjustmentDetailStore.adjustmentDetailList = [...temp]
        await adjustmentStore.fetchWritingAdjustment({
          artistId: query.artistId,
          companyId: user.companyId,
        })
        savingFinish()
      }
      setIsDeleteModalVisible(false)
    })
    setIsDeleteModalVisible(true)
  }

  const showAddAdjustmentTypeModal = ({ adjustmentDetailId }) => {
    setHandleAddAdjustmentType(() => async newTypeText => {
      savingStart()
      const newType = await adjustmentTypeStore.createAdjustmentType({
        artistId: query.artistId,
        adjustmentContractId: adjustmentContractStore.contracts.find(
          contract => contract.isDefault === true,
        )._id,
        type: newTypeText,
      })
      if (newType) {
        const res = await adjustmentDetailStore.updateAdjustmentDetail({
          adjustmentId: writingAdjustment._id,
          adjustmentDetailId,
          params: {
            typeId: newType._id,
          },
        })
        await adjustmentDetailStore.fetchAdjustmentDetail({
          adjustmentId: writingAdjustment._id,
        })
        await adjustmentTypeStore.fetchAdjustmentTypeList({
          artistId: query.artistId,
          companyId: selectedCompanyId,
        })
      }
      setNewAdjustmentType('')
      savingFinish()
      setIsAddAdjustmentTypeModalVisible(false)
    })
    setIsAddAdjustmentTypeModalVisible(true)
  }

  const handleOpenChange = open => {
    if (!open) {
      setIsOpenStartDatePicker(false)
    }
  }

  const handleOpenLoadMusicStatementModal = async () => {
    if (user.type !== 'company') return

    setIsLoadMusicDataModalVisible(true)
  }

  const query = qs.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  })

  const _init = async () => {
    setIsLoading(true)
    setSelectedCompanyId(
      user?.type === 'company' ? user?.companyId : selectedCompanyId,
    )

    if (query.artistId && query.artistId !== 'null') {
      // 현재 보고 있는 아티스트 (개인, 그룹 구별용)
      setCurrentArtistId(query.artistId)
      /** company =>  */
      if (user?.type === 'company') {
        await authStore.artistViewMode(query.artistId)
      }

      if (user?.type === 'artist') {
        const companys = await adjustmentStore.fetchAdjustmentCompanyList({
          isConfirmed: 0,
          artistId: query.artistId,
        })

        if (!companys || companys.length === 0) {
          // alert('소속사를 불러오는데 실패했습니다. 관리자에게 문의하세요')
          console.error('소속사를 불러오는데 실패했습니다.')
          setIsLoading(false)
          return
        }
        setCompanyList(companys)
        if (!selectedCompanyId) {
          setSelectedCompanyId(companys?.[0]._id)
        }
      }

      // type list 필요해서 패치 (detail create, update 및 type list에서 필요)
      await adjustmentTypeStore.fetchAdjustmentTypeList({
        artistId: query.artistId,
        companyId: selectedCompanyId,
      })

      await adjustmentContractStore.fetchAdjustmentContractList({
        artistId: query.artistId,
        companyId: selectedCompanyId,
      })

      await adjustmentStore.fetchWritingAdjustment({
        artistId: query.artistId,
        companyId: selectedCompanyId,
      })

      const { writingAdjustment } = adjustmentStore
      if (writingAdjustment) {
        const adjustmentDetails = await adjustmentDetailStore.fetchAdjustmentDetail(
          {
            adjustmentId: writingAdjustment._id,
            defaultAdjustmentTypeId: adjustmentTypeStore.adjustmentTypeList.find(
              type => type.isDefault === true,
            )._id,
          },
        )

        await adjustmentStore.fetchWritingAdjustment({
          artistId: query.artistId,
          companyId: selectedCompanyId,
        })
      }

      // 아티스트가 그룹도 보기위해..
      if (user?.type === 'artist') {
        // currentArtistId가 아니라 현재 아티스트 아이디
        const artists = await adjustmentStore.fetchAdjustmentAllowArtistList()

        if (artists && artists.length > 0) {
          const _sortedArtist = artists.sort((a, b) => {
            if (a.type === '그룹' && b.type === '개인') return -1 // '그룹'이 '개인'보다 먼저 오도록
            if (a.type === '개인' && b.type === '그룹') return 1 // '개인'이 '그룹'보다 나중에 오도록
            return 0 // 동일한 경우 순서 변경 없음
          })
          setAllowViewArtistList([..._sortedArtist])
        }
      }
    }
    setIsLoading(false)
  }

  const _fetch = async () => {
    setIsLoading(true)

    if (!selectedCompanyId) return

    if (query.artistId && query.artistId !== 'null') {
      await adjustmentStore.fetchWritingAdjustment({
        artistId: query.artistId,
        companyId: selectedCompanyId,
      })

      const { writingAdjustment } = adjustmentStore || {}
      if (writingAdjustment) {
        const adjustmentDetails = await adjustmentDetailStore.fetchAdjustmentDetail(
          { adjustmentId: writingAdjustment._id },
        )
      }

      adjustmentTypeStore.fetchAdjustmentTypeList({
        artistId: query.artistId,
        companyId: selectedCompanyId,
      })

      adjustmentContractStore.fetchAdjustmentContractList({
        artistId: query.artistId,
        companyId: selectedCompanyId,
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    _init()
  }, [artistStore])

  useEffect(() => {
    _fetch()
  }, [selectedCompanyId])

  const { imageUri, name } = artist || {}
  const { writingAdjustment } = adjustmentStore || {}
  const { adjustmentDetailList } = adjustmentDetailStore || {}
  const filteredAdjustmentDetailList = adjustmentDetailList.filter(item => {
    if (selectedTypes.length === 0) return true
    return selectedTypes.includes(item.type)
  })

  const selectedCompany = companyList.find(
    company => company._id === selectedCompanyId,
  )

  const isDeleted = selectedCompany?.connectionInfo?.isDeleted

  const onClickViewSettlementDetail = async month => {
    await authStore.artistViewMode(currentArtistId)
    window.open(`/insight/settlement/month?date=${month}`)
  }

  const columns = useMemo(
    () =>
      getColumns({
        adjustmentStore,
        adjustmentDetailStore,
        currentArtistId,
        readOnly,
        user,
        artistId: currentArtistId,
        adjustmentDetailList,
        allAdjustmentTypeList,
        updateAdjustmentDetail,
        setIsOpenStartDatePicker,
        savingFinish,
        updateCell,
        savingStart,
        showAddAdjustmentTypeModal,
        handleDeleteFile,
        openFile,
        showDeleteModal,
        onClickViewSettlementDetail,
      }),
    [
      adjustmentDetailStore.adjustmentDetailList,
      adjustmentContractStore.contracts,
      currAdjustmentTypes,
      newAdjustmentType,
      adjustmentTypeStore.adjustmentTypeList,
      allAdjustmentTypeList,
    ],
  )

  const renderContent = () => {
    if (isLoading) {
      return <Spin />
    }
    else if (writingAdjustment) {
      return (
        <>
          <ReadOnlyInfoText readOnly={readOnly} isDeleted={isDeleted} />
          <TopContainer>
            <TitleSubContainer>
              <TitleSavingStatus
                isSaving={isSaving}
                showSaving={showSaving}
                lastSavedTime={lastSavedTime}
              />
              <AdjustmentTitleInput
                readOnly={readOnly}
                title={adjustmentStore?.writingAdjustment?.title}
                onUpdateTitle={
                  newTitle => {
                    adjustmentStore.writingAdjustment.title = newTitle
                  }
                }
                onSaveTitle={
                  newTitle => {
                    updateAdjustment({ params: { title: newTitle } })
                  }
                }
              />
            </TitleSubContainer>
            <StartDatePicker
              readOnly={readOnly}
              setIsOpenStartDatePicker={setIsOpenStartDatePicker}
              isOpenStartDatePicker={isOpenStartDatePicker}
              handleOpenChange={handleOpenChange}
              adjustmentDetailStore={adjustmentDetailStore}
              adjustmentStore={adjustmentStore}
              updateAdjustment={updateAdjustment}
            />
          </TopContainer>

          <BottomContainer>
            <SheetContainer>
              <TagButtonContainer>
                <AdjustmentTypeHeader
                  typeList={currAdjustmentTypes}
                  selectedTypes={selectedTypes}
                  onClick={handleClickAdjustmentType}
                />
                {
                  user.type === 'company' && (
                    <LoadMusicData onClick={handleOpenLoadMusicStatementModal}>
                    정산 내역 불러오기
                    </LoadMusicData>
                  )
                }
              </TagButtonContainer>
              <CompanyRoyaltyStatementTable
                data={filteredAdjustmentDetailList}
                columns={columns}
                updateCell={updateCell}
                changeRow={changeRow}
                readOnly={readOnly}
              />
              <AddRowButton
                addRow={addRow}
                readOnly={readOnly}
                isSaving={isSaving}
              />
            </SheetContainer>
            <TotalInfoContainer>
              <TotalInfoMarginBox />
              <TotalInfo
                amountInfo={writingAdjustment?.amountInfo}
                selectedTypes={selectedTypes}
                onCarryOverChange={onCarryOverChange}
                isUpdatingCheckbox={isUpdatingCheckbox}
                updatingId={updatingId}
                readOnly={readOnly}
                isClosed={false}
              />
            </TotalInfoContainer>
          </BottomContainer>
        </>
      )
    }
    else {
      return <WritingEmpty />
    }
  }

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        <RoyaltyStatementContainer>
          <TopHeader
            currentArtistId={currentArtistId}
            allowViewArtistList={allowViewArtistList}
            readOnly={readOnly}
            onClosingClick={showClosingAdjustmentModal}
            imageUri={imageUri}
            name={name}
            type={user?.type}
          />
          <CompanySelector
            isLender={user?.type === 'artist' && !isLoading}
            onChange={
              _companyId => {
                setSelectedCompanyId(_companyId)
              }
            }
            selectedCompanyId={selectedCompanyId}
            companyList={companyList}
          />
          {renderContent()}
        </RoyaltyStatementContainer>
        <AddAdjustmentTypeModal
          isVisible={isAddAdjustmentTypeModalVisible}
          onAdd={handleAddAdjustmentType}
          onCancel={handleCancelAddAdjustmentTypeModal}
          newTypeText={newAdjustmentType}
          setNewTypeText={setNewAdjustmentType}
        />
        <DeleteConfirmModal
          isVisible={isDeleteModalVisible}
          onConfirm={handleDelete}
          onClose={() => setIsDeleteModalVisible(false)}
        />
        <ClosingAdjustmentModal
          isVisible={isClosingModalVisible}
          onClose={handleCloseClosingModal}
          onConfirm={handleConfirmClosingModal}
          adjustmentDetailList={adjustmentDetailList}
        />
        <LoadMusicDataModal
          isVisible={isLoadMusicDataModalVisible}
          onClose={() => setIsLoadMusicDataModalVisible(false)}
          onAddComplete={
            async selectedItem => {
              if (!selectedItem) return

              const defaultTypeId = adjustmentTypeStore.adjustmentTypeList.find(
                type => type.isDefault === true,
              )?._id

              const month = parseInt(selectedItem.month.toString().slice(-2), 10)
              const year =
              2000 + parseInt(selectedItem.year.toString().slice(0, 2), 10)
              const fee = Math.round(selectedItem.artistMonthlySettlementFee)

              const params = {
                typeId: defaultTypeId,
                tag: '수입',
                date: moment().format('YYYYMMDD'),
                description: `${year}년 ${month}월 정산금액`,
                amount: fee,
                settlementInfo: {
                  companyId: selectedCompanyId,
                  artistId: query.artistId,
                  month: selectedItem.month,
                },
              }
              const res = await adjustmentDetailStore.createAdjustmentDetail({
                adjustmentId: adjustmentStore.writingAdjustment._id,
                params,
              })

              if (res) {
                adjustmentDetailStore.adjustmentDetailList = [
                  ...adjustmentDetailStore.adjustmentDetailList,
                  res,
                ]

                await adjustmentStore.fetchWritingAdjustment({
                  artistId: query.artistId,
                  companyId: user.companyId,
                })
              }

              setIsLoadMusicDataModalVisible(false)
            }
          }
          fetchData={
            year =>
            settlementStore?.fetchSettlementCompanyArtistAnnual({
              artistObjectId: query.artistId,
              companyObjectId: selectedCompanyId,
              __year: year,
            })
          }
        />
      </RootTemplate>
    </>
  )
}

export default inject(
  'authStore',
  'artistStore',
  'adjustmentStore',
  'adjustmentContractStore',
  'adjustmentDetailStore',
  'adjustmentTypeStore',
  'settlementStore',
)(observer(CompanyRoyaltyStatementPage))
