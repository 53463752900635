import { observable } from 'mobx'
import { STORAGE_URL, DOMAIN_URL } from '@consts'
import { AlbumModel, TrackModel } from '.'

export default class ArtistModel {
  /* Database fields */
  @observable _id

  @observable type
  @observable imageOriginalPath
  @observable image64Path
  @observable image128Path
  @observable image512Path

  @observable description
  @observable facebookUrl
  @observable instagramUrl
  @observable youtubeUrl
  @observable soundcloudUrl
  @observable homepageUrl
  @observable emailUrl
  @observable twitterUrl
  @observable weverseUrl
  @observable etcSocialUrl
  @observable tiktokUrl

  //
  @observable spotifyUrl
  @observable youtubeMusicUrl
  @observable melonUrl
  @observable genieUrl
  @observable bugsUrl
  @observable vibeUrl
  @observable floUrl
  @observable appleMusicUrl
  @observable amazonMusicUrl
  @observable tidalUrl
  @observable deezerUrl
  @observable jooxUrl
  @observable mymusicUrl
  @observable kkboxUrl
  @observable linejpUrl
  @observable linetwUrl
  @observable yandexUrl
  @observable nctUrl
  @observable zingUrl
  @observable anghmiUrl

  @observable isDisplaySpotifyUrl
  @observable isDisplayYoutubeMusicUrl
  @observable isDisplayMelonUrl
  @observable isDisplayGenieUrl
  @observable isDisplayBugsUrl
  @observable isDisplayVibeUrl
  @observable isDisplayFloUrl
  @observable isDisplayAppleMusicUrl
  @observable isDisplayAmazonMusicUrl
  @observable isDisplayTidalUrl
  @observable isDisplayDeezerUrl
  @observable isDisplayJooxUrl
  @observable isDisplayMymusicUrl
  @observable isDisplayKkboxUrl
  @observable isDisplayLinejpUrl
  @observable isDisplayLinetwUrl
  @observable isDisplayYandexUrl
  @observable isDisplayNctUrl
  @observable isDisplayZingUrl
  @observable isDisplayAnghmiUrl
  //

  @observable createdAt
  @observable updatedAt
  @observable deletedAt

  @observable userId
  @observable uniqueName
  @observable isAuto
  @observable likeCount

  @observable becauseOfAlbumId
  @observable becauseOfTrackId
  @observable becauseOfArtistId
  @observable becauseOfUserId

  @observable isDisplayFollowerData
  @observable isDisplayInstagramData
  @observable isDisplayTwitterData
  @observable isDisplayYoutubeData
  @observable isDisplaySoundcloudData

  @observable releaseTrackList = []
  @observable releaseAlbumList = []
  @observable participationTrackList = []
  @observable participationAlbumList = []

  @observable nowLikeCrawling
  @observable isNowCrawling
  @observable subdomainInfo
  @observable isExistsSubdomainNotificaion
  @observable stageNameList

  @observable agencyInfo
  /* Database fields end */

  @observable name

  // StageNameList 내의 대표 본 가수 이름
  @observable masterName

  @observable nameEng

  @observable artistUrl

  get imageUri() {
    return `${STORAGE_URL}${this.imageOriginalPath}`
  }

  @observable isOwner

  constructor(stores, props) {
    if (props) {
      this._id = props._id

      this.type = props.type
      this.UPC = props.UPC

      this.imageOriginalPath = props.imageOriginalPath
      this.image64Path = props.image64Path
      this.image128Path = props.image128Path
      this.image512Path = props.image512Path

      this.name =
        props.name ||
        (props.stageNameList &&
          props.stageNameList.filter(elem => elem._id === elem.artistId) &&
          props.stageNameList.filter(elem => elem._id === elem.artistId)[0]
            .name)

      // 활동명 중 아티스트 ID와 같은 본 가수 이름 담기
      this.masterName =
        props.stageNameList &&
        props.stageNameList.filter(elem => elem._id === elem.artistId) &&
        props.stageNameList.filter(elem => elem._id === elem.artistId)[0].name

      this.nameEng = props.nameEng

      this.description = props.description
      this.facebookUrl = props.facebookUrl
      this.instagramUrl = props.instagramUrl
      this.youtubeUrl = props.youtubeUrl
      this.soundcloudUrl = props.soundcloudUrl
      this.homepageUrl = props.homepageUrl
      this.emailUrl = props.emailUrl
      this.twitterUrl = props.twitterUrl
      this.weverseUrl = props.weverseUrl
      this.etcSocialUrl = props.etcSocialUrl
      this.tiktokUrl = props.tiktokUrl

      //
      this.spotifyUrl = props.spotifyUrl
      this.youtubeMusicUrl = props.youtubeMusicUrl
      this.melonUrl = props.melonUrl
      this.genieUrl = props.genieUrl
      this.bugsUrl = props.bugsUrl
      this.vibeUrl = props.vibeUrl
      this.floUrl = props.floUrl
      this.appleMusicUrl = props.appleMusicUrl
      this.amazonMusicUrl = props.amazonMusicUrl
      this.tidalUrl = props.tidalUrl
      this.deezerUrl = props.deezerUrl
      this.jooxUrl = props.jooxUrl
      this.mymusicUrl = props.mymusicUrl
      this.kkboxUrl = props.kkboxUrl
      this.linejpUrl = props.linejpUrl
      this.linetwUrl = props.linetwUrl
      this.yandexUrl = props.yandexUrl
      this.nctUrl = props.nctUrl
      this.zingUrl = props.zingUrl
      this.anghmiUrl = props.anghmiUrl

      this.isDisplaySpotifyUrl = props.isDisplaySpotifyUrl
      this.isDisplayYoutubeMusicUrl = props.isDisplayYoutubeMusicUrl
      this.isDisplayMelonUrl = props.isDisplayMelonUrl
      this.isDisplayGenieUrl = props.isDisplayGenieUrl
      this.isDisplayBugsUrl = props.isDisplayBugsUrl
      this.isDisplayVibeUrl = props.isDisplayVibeUrl
      this.isDisplayFloUrl = props.isDisplayFloUrl
      this.isDisplayAppleMusicUrl = props.isDisplayAppleMusicUrl
      this.isDisplayAmazonMusicUrl = props.isDisplayAmazonMusicUrl
      this.isDisplayTidalUrl = props.isDisplayTidalUrl
      this.isDisplayDeezerUrl = props.isDisplayDeezerUrl
      this.isDisplayJooxUrl = props.isDisplayJooxUrl
      this.isDisplayMymusicUrl = props.isDisplayMymusicUrl
      this.isDisplayKkboxUrl = props.isDisplayKkboxUrl
      this.isDisplayLinejpUrl = props.isDisplayLinejpUrl
      this.isDisplayLinetwUrl = props.isDisplayLinetwUrl
      this.isDisplayYandexUrl = props.isDisplayYandexUrl
      this.isDisplayNctUrl = props.isDisplayNctUrl
      this.isDisplayZingUrl = props.isDisplayZingUrl
      this.isDisplayAnghmiUrl = props.isDisplayAnghmiUrl
      //

      this.createdAt = props.createdAt
      this.updatedAt = props.updatedAt
      this.deletedAt = props.deletedAt

      this.userId = props.userId
      this.artistId = props.artistId
      this.uniqueName = props.uniqueName
      this.isAuto = props.isAuto
      this.likeCount = props.likeCount

      this.becauseOfAlbumId = props.becauseOfAlbumId
      this.becauseOfTrackId = props.becauseOfTrackId
      this.becauseOfArtistId = props.becauseOfArtistId
      this.becauseOfUserId = props.becauseOfUserId

      this.isExistsSubdomainNotificaion = props.isExistsSubdomainNotificaion

      this.releaseTrackList =
        (props.releaseTrackList &&
          props.releaseTrackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(stores, elem))) ||
        []
      this.releaseAlbumList =
        (props.releaseAlbumList &&
          props.releaseAlbumList
            .filter(elem => !!elem)
            .map(elem => new AlbumModel(stores, elem))) ||
        []
      this.participationTrackList =
        (props.participationTrackList &&
          props.participationTrackList
            .filter(elem => !!elem)
            .map(elem => new TrackModel(stores, elem))) ||
        []
      this.participationAlbumList =
        (props.participationAlbumList &&
          props.participationAlbumList
            .filter(elem => !!elem)
            .map(elem => new AlbumModel(stores, elem))) ||
        []

      this.nowLikeCrawling = props.nowLikeCrawling
      this.isNowCrawling = props.isNowCrawling
      this.isDisplayFollowerData = props.isDisplayFollowerData
      this.isDisplayInstagramData = props.isDisplayInstagramData
      this.isDisplayTwitterData = props.isDisplayTwitterData
      this.isDisplayYoutubeData = props.isDisplayYoutubeData
      this.isDisplaySoundcloudData = props.isDisplaySoundcloudData

      this.subdomainInfo = props.subdomainInfo
      this.agencyInfo = props.agencyInfo

      // 대표 활동명 제거
      this.stageNameList =
        props.stageNameList &&
        props.stageNameList.splice(
          props.stageNameList.findIndex(item => item._id === item.artistId),
          1,
        ) &&
        props.stageNameList

      this.artistUrl =
        props.subdomainInfo &&
          props.subdomainInfo.subdomain &&
          props.subdomainInfo.isAccepted
          ? `https://${props.subdomainInfo.subdomain}.${DOMAIN_URL}`
          : props.customUrlInfo && props.customUrlInfo.autoUrl
            ? `https://${DOMAIN_URL}/artist/${props &&
            props.customUrlInfo &&
            props.customUrlInfo.autoUrl}`
            : `https://${DOMAIN_URL}/artist/${props && props.uniqueName}`

      // 회사일 경우, 회사와 연결된 가수 리스트랑 해당 아티스트를 비교해서 권한 검사
      this.isOwner =
        stores.authStore.currentUser.type === 'company'
          ? stores.connectionsStore.artistCompanyConnectionList.filter(
            elem => elem.targetId === props._id,
          ).length === 0
            ? false
            : true
          : stores.authStore.currentUser.artistId === props._id
    }
  }
}
