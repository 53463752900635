import React from 'react'
import styled from 'styled-components'
import { Flex, Text, H3, Container, H6, H4 } from '@components/atoms'

import { ChartDivisionBox, IncreaseTextBox } from '@components/molecules'
import {
  ArtistSnsSubFollowerChart,
  DailySnsSubFollowerTableChart,
} from '@components/organisms'
import { dateYMDMM, convertToPricingComma } from '@utils/format'

import {
  copyGrayImg,
  SNS_SOURCES,
} from '@images/'
import { useLocation } from 'react-router-dom'

const Header = styled(Flex)`
  /* width: '100%'; */
  height: 73px;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 0.5px #242424;
`
const Content = styled(Flex)`
  /* width: '100%'; */
  /* height: '100%'; */
  flex-direction: column;
  padding: 50px 50px 50px 0;
`

const SectionTitle = styled(Flex)`
  flex-direction: column;
  text-align: left;
  width: 100%;

  margin-bottom: 30px;
`

const SnsInfo = styled(Flex)`
  align-items: center;
  height: 48px;
  margin: 0 0 30px 0;
  gap: 4px;

  img {
    width: 48px;
    height: 48px;
  }
`

export const InsightArtistSnsSubTemplate = ({
  artist,
  artistTrend,
  periodType,
  setPeriodType,
  snsType,
}) => {
  const likeCountInfo = artistTrend?.likeCountInfo || null
  const followersInfo = artistTrend?.followersInfo || null
  const totalDailyList = artistTrend?.totalDailyList || []
  const isNowCrawling = artistTrend?.isNowCrawling || false

  const location = useLocation()

  return (
    <>
      <Container>
        <Header>
          <Text
            type="Medium"
            size="18px"
            style={{ marginTop: '10px', textTransform: 'capitalize' }}
          >
            {snsType === 'twitter' ? 'x(twitter)' : snsType}
          </Text>
          <Text
            type="Medium"
            color="#949494"
            size="14px"
            style={{ margin: '10px 50px 0 0' }}
          >
            {
              artist && artist.nowLikeCrawling
                ? '정보를 업데이트 중 입니다.'
                : artistTrend && artistTrend.trendDataAddedAt
                  ? `${artistTrend
                && dateYMDMM(artistTrend.trendDataAddedAt)} 업데이트`
                  : '업데이트할 데이터가 없습니다.'
            }
          </Text>
        </Header>
        <Content>
          <Flex style={{ justifyContent: 'space-between' }}>
            <Flex type="column">
              <SnsInfo>
                <img
                  src={SNS_SOURCES[snsType] || false}
                  alt="sns_img"
                />
                <H3
                  align="left"
                  style={
                    {
                      textTransform: 'capitalize',
                    }
                  }
                >
                  {snsType === 'twitter' ? 'x(twitter)' : snsType}
                </H3>
              </SnsInfo>
              <SectionTitle>
                <H6
                  align="left"
                  type="bold"
                  color="#949494"
                  style={{ marginBottom: 6 }}
                >
                  Total Daily
                </H6>
                <Flex style={{ gap: '20px' }}>
                  <Flex align="center">
                    <H4 type="bold">
                      {
                        `${convertToPricingComma(
                        followersInfo?.today && followersInfo?.today?.[snsType],
                        ) || 0} Follower`
                      }
                    </H4>
                    <IncreaseTextBox
                      data={
                        (followersInfo?.today?.increaseValue
                          && followersInfo.today.increaseValue?.[snsType])
                        || 0
                      }
                      style={{ marginLeft: '12px' }}
                    />
                  </Flex>
                  {
                    (snsType === 'genie' || snsType === 'bugs') && (
                      <Flex align="center">
                        <H4 type="bold">
                          {
                            `${convertToPricingComma(
                          likeCountInfo?.today?.[snsType],
                            ) || 0} Like`
                          }
                        </H4>
                        <IncreaseTextBox
                          data={likeCountInfo?.increaseValue?.[snsType] || 0}
                          style={{ marginLeft: '12px' }}
                        />
                      </Flex>
                    )
                  }
                </Flex>
              </SectionTitle>
            </Flex>
            <Flex align="center" style={{ gap: 30, height: 50 }}>
              {
                ((snsType === 'youtube' && artist?.youtubeUrl)
                || snsType !== 'youtube') && (
                  <Flex
                    justify="center"
                    align="center"
                    style={
                      {
                        width: 28,
                        height: 28,
                        borderRadius: '50%',
                        backgroundColor: '#f4f4f4',
                        cursor: 'pointer',
                      }
                    }
                    onClick={
                      () => {
                        let url = window.document.location.host + location.pathname
                        switch (snsType) {
                          case 'melon':
                            url = artist.melonUrl
                            break
                          case 'instagram':
                            url = artist.instagramUrl
                            break
                          case 'youtube':
                            url = artist.youtubeUrl
                            break
                          case 'bugs':
                            url = artist.bugsUrl
                            break
                          case 'genie':
                            url = artist.genieUrl
                            break
                          case 'twitter':
                            url = artist.twitterUrl
                            break
                          case 'soundcloud':
                            url = artist.soundcloudUrl
                            break
                          default:
                            break
                        }
                        window.open(url)
                      }
                    }
                  >
                    <img src={copyGrayImg} width={20} height={20} alt="copy" />
                  </Flex>
                )
              }
              <div style={{ width: '258px' }}>
                <ChartDivisionBox
                  options={
                    [
                      { label: '전체', value: 'all' },
                      { label: '지난 1일', value: 'daily' },
                      { label: '지난 7일', value: 'weekly' },
                      { label: '지난 28일', value: 'monthly' },
                    ]
                  }
                  value={periodType}
                  onChange={value => setPeriodType(value)}
                />
              </div>
            </Flex>
          </Flex>
          <ArtistSnsSubFollowerChart
            dailyListData={totalDailyList}
            snsType={snsType}
            isNowCrawling={isNowCrawling}
          />

          <DailySnsSubFollowerTableChart
            data={totalDailyList}
            snsType={snsType}
          />
        </Content>
      </Container>
    </>
  )
}
