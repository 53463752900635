/* eslint-disable import/no-unresolved */
import { action, observable } from 'mobx'
import { AdjustmentModel, ArtistModel } from '@stores/models'
import { Network } from '../networks'
import { Store } from '..'

export default class AdjustmentStore {
  @observable writingAdjustment = null
  @observable adjustmentList = []

  constructor(store: Store, network: Network) {
    this.store = store
    this.network = network

    this.init()
  }

  @action.bound
  init() {}

  /**
   * [정산서를 조회할 수 있는 권한이 있는 아티스트 조회]
   * 현재 유저 기준이므로 viewArtist아이디가 아니라 현재 아티스트 아이디
   */
  async fetchAdjustmentAllowArtistList({ isConfirmed = 0 } = {}) {
    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchAdjustmentAllowArtistList] currentUser is not exist')
      return
    }

    const { artistId } = this.store.authStore.currentUser

    if (!artistId) {
      console.error('[fetchAdjustmentAllowArtistList] artistId is not exist')
      return
    }

    const res = await this.network.adjustmentNetwork.getAdjustmentAllowArtistList(
      { artistId, isConfirmed },
    )

    if (!res || res.error) {
      console.error('[fetchAdjustmentAllowArtistList] failed to network')
      return
    }

    return res.artistList.map(artist => new ArtistModel(this.store, artist))
  }

  /**
   * 정산서의 해당 구분 이월하기
   */
  async createAdjustmentTypeCarryOver({ adjustmentId, adjustmentTypeId } = {}) {
    if (!adjustmentId) {
      console.error('[postAdjustmentTypeCarryOver] adjustmentId is not exist')
      return
    }

    if (!adjustmentTypeId) {
      console.error(
        '[postAdjustmentTypeCarryOver] adjustmentTypeId is not exist',
      )
      return
    }

    const params = {
      adjustmentTypeId,
    }

    const res = await this.network.adjustmentNetwork.postAdjustmentTypeCarryOver(
      { adjustmentId, params },
    )

    if (!res || res.error) {
      console.error('[postAdjustmentTypeCarryOver] failed to network')
      return
    }

    const { adjustmentCarryOver } = res.data

    return adjustmentCarryOver
  }

  /**
   * 정산서의 해당 구분 이월해제 하기
   */
  async deleteAdjustmentTypeCarryOver({ adjustmentId, adjustmentTypeId } = {}) {
    if (!adjustmentId) {
      console.error('[deleteAdjustmentTypeCarryOver] adjustmentId is not exist')
      return
    }

    if (!adjustmentTypeId) {
      console.error(
        '[deleteAdjustmentTypeCarryOver] adjustmentTypeId is not exist',
      )
      return
    }

    const params = {
      adjustmentTypeId,
    }

    const res = await this.network.adjustmentNetwork.deleteAdjustmentTypeCarryOver(
      { adjustmentId, params },
    )

    if (!res || res.error) {
      console.error('[deleteAdjustmentTypeCarryOver] failed to network')
      return
    }

    return res
  }

  /**
   * [정산서를 작성한 사업자 리스트 조회]
   */
  async fetchAdjustmentCompanyList({ isConfirmed, artistId } = {}) {
    if (!artistId) {
      console.error('[fetchAdjustmentCompanyList] artistId is not exist')
      return
    }

    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchAdjustmentCompanyList] currentUser is not exist')
      return
    }

    if (!artistId) {
      console.error('[fetchAdjustmentCompanyList] artistId is not exist')
      return
    }

    const res = await this.network.adjustmentNetwork.getAdjustmentCompanyList({
      artistId,
      isConfirmed,
    })

    if (!res || res.error) {
      console.error('[fetchAdjustmentCompanyList] failed to network')
      return
    }

    return res.companyList
  }

  /**
   * [정산서 조회]
   */
  async fetchAdjustment({ adjustmentId } = {}) {
    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchAdjustment] currentUser is not exist')
      return
    }

    if (!adjustmentId) {
      console.error('[fetchAdjustment] adjustmentId is not exist')
      return
    }

    const res = await this.network.adjustmentNetwork.getAdjustment({
      adjustmentId,
    })
    if (!res || res.error) {
      console.error('[fetchAdjustment] failed to network')
      return
    }

    const { adjustment } = res

    return new AdjustmentModel(this.store, adjustment)
  }

  /**
   * [현재 작성중인 정산서 조회]
   */
  async fetchWritingAdjustment({ artistId, companyId } = {}) {
    const { selectedCompanyId } = this.store.adjustmentContractStore

    if (!artistId) {
      console.error('[fetchWritingAdjustment] artistId is not exist')
      this.writingAdjustment = null
      return
    }

    if (!companyId) {
      if (selectedCompanyId) {
        companyId = selectedCompanyId
        console.warn(
          '[fetchWritingAdjustment] companyId is not exist, use selectedCompanyId',
        )
      }
      else {
        console.error('[fetchWritingAdjustment] companyId is not exist')
        this.writingAdjustment = null
        return
      }
    }

    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchWritingAdjustment] currentUser is not exist')
      this.writingAdjustment = null
      return
    }

    const res = await this.network.adjustmentNetwork.getAdjustmentList({
      artistId,
      companyId,
      isConfirmed: 0,
    })

    if (!res || res.error) {
      console.error('[fetchWritingAdjustment] failed to network')
      this.writingAdjustment = null
      return
    }

    const { adjustmentList } = res.data

    if (adjustmentList.length === 0) {
      const adjustment = await this.createAdjustment()
      this.writingAdjustment = new AdjustmentModel(this.store, adjustment)
    }
    else {
      /**
       * 지금은 writingadjustment가 1개밖에 없기 때문에 무조건 0번째만 받으면 됨
       * => 추후 여러개가 된다면 리스트로 관리
       * */

      // adjustmentTypeList가 fetchAdjustment에만 있어서 다시 패치
      const _res = await this.network.adjustmentNetwork.getAdjustment({
        adjustmentId: adjustmentList[0]._id,
      })

      if (!_res || _res.error) {
        console.error('[fetchWritingAdjustment] failed to network')
        this.writingAdjustment = null
        return
      }

      const { adjustment } = _res

      adjustment.adjustmentTypeList = _res?.adjustment?.adjustmentTypeList

      this.writingAdjustment = new AdjustmentModel(this.store, adjustment)
    }

    return this.writingAdjustment
  }

  /**
   * [정산서 리스트 조회]
   * - 작성중인 정산서 조회 X
   * - 마감된 정산서만 조회
   */
  async fetchAdjustmentList({ artistId, companyId } = {}) {
    if (!artistId) {
      console.error('[fetchAdjustmentList] artistId is not exist')
      this.adjustmentList = []
      return
    }

    if (!companyId) {
      console.error('[fetchAdjustmentList] companyId is not exist')
      this.adjustmentList = []
      return
    }

    if (!this.store?.authStore?.currentUser) {
      console.error('[fetchAdjustmentList] currentUser is not exist')
      return
    }
    const { type } = this.store.authStore.currentUser

    const res = await this.network.adjustmentNetwork.getAdjustmentList({
      artistId,
      companyId,
      isConfirmed: 1,
    })
    if (!res || res.error) {
      console.error('[fetchAdjustmentList] failed to network')
      return
    }

    // 최신순으로 정렬
    this.adjustmentList = res.data.adjustmentList
      .map(adjustment => new AdjustmentModel(this.store, adjustment))
      .reverse()

    return this.adjustmentList
  }

  /**
   * [정산서 생성]
   * - companyId, artistId만 들어간 빈 정산서 생성
   * - 추가기능 X
   */
  async createAdjustment() {
    if (!this.store?.authStore?.currentUser) {
      console.error('[createAdjustment] currentUser is not exist')
      return
    }

    const { companyId, artistId } = this.store.authStore.currentUser

    if (!companyId || !artistId) {
      console.error('[createAdjustment] companyId or artistId is not exist')
      return
    }
    const params = {
      companyId,
      artistId,
    }
    const res = await this.network.adjustmentNetwork.postAdjustment({ params })
    if (!res || res.error) {
      console.error('[createAdjustment] failed to network')
      return
    }

    return res?.data?.adjustment
  }

  /**
   * [정산서 수정]
   * params에 들어온 값 업데이트
   * 현재 작성중인 정산서에 대해서만 수정가능
   */
  async updateAdjustment({ params } = {}) {
    if (!this.store?.authStore?.currentUser) {
      console.error('[updateAdjustment] currentUser is not exist')
      return
    }

    if (!params) {
      console.error('[updateAdjustment] params is not exist')
      return
    }

    if (!this.writingAdjustment) {
      console.error('[updateAdjustment] writingAdjustment is not exist')
      return
    }

    const { _id } = this.writingAdjustment

    const res = await this.network.adjustmentNetwork.putAdjustment({
      adjustmentId: _id,
      params,
    })
    if (!res || res.error) {
      console.error('[updateAdjustment] failed to network')
      return
    }

    return res.data
  }

  /**
   * [정산서 삭제]
   * 해당 id 정산서 삭제
   */
  async deleteAdjustment({ adjustmentId } = {}) {
    if (!this.store?.authStore?.currentUser) {
      console.error('[deleteAdjustment] currentUser is not exist')
      return
    }

    if (!adjustmentId) {
      console.error('[deleteAdjustment] adjustmentId is not exist')
      return
    }

    const res = await this.network.adjustmentNetwork.deleteAdjustment({
      adjustmentId,
    })

    if (!res || res.error) {
      console.error('[deleteAdjustment] failed to network')
      return
    }

    return res
  }
}
