import React, { useEffect, useState } from 'react'
import { RootTemplate, InsightArtistTemplate } from '@components/templates'
import { observer } from 'mobx-react'
import { Redirect } from 'react-router-dom'
import qs from 'qs'
import { useStore } from '@utils/hooks'

interface Props {
  match: Any;
  location: Any;
  logout: Function;
  user: Object;
}

const InsightArtistPage = ({ location, logout, user }: Props) => {
  const { artistStore, trendStore } = useStore()

  const artist = artistStore.artistDetail
  const { artistTrend } = trendStore

  const fetchArtistDetail = artistStore.fetchArtistDetail || (() => {})

  const fetchArtistTrend = trendStore.fetchArtistTrend || (() => {})
  const fetchArtistTrendTrack = trendStore.fetchArtistTrendTrack || (() => {})

  const [periodType, setPeriodType] = useState({
    label: '지난 28일',
    value: 'monthly',
  })

  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
      // 문자열 맨 앞의 ?를 생력
    })
    if (query.artistId && query.artistId !== 'null') {
      fetchArtistDetail(query.artistId)
    }
    else {
      fetchArtistDetail(user && user['artistId'])
    }
  }, [user, artistStore, fetchArtistDetail, periodType, location])

  useEffect(() => {
    if (artist) {
      const __periodDays =
        periodType.value === 'weekly'
          ? 7
          : periodType.value === 'monthly'
            ? 28
            : periodType.value === 'daily'
              ? 1
              : periodType.value === 'all'
                ? 365 * 10
                : null

      fetchArtistTrend(artist?._id, { __periodDays })
      fetchArtistTrendTrack(artist?._id, { __periodDays })
    }
  }, [periodType, artist, fetchArtistTrend, fetchArtistTrendTrack])

  return (
    <>
      <RootTemplate user={user} artist={artist} logout={logout}>
        {!(user && user['artistId']) && <Redirect to="/" />}
        <InsightArtistTemplate
          artist={artist}
          artistTrend={artistTrend}
          periodType={periodType}
          setPeriodType={setPeriodType}
        />
      </RootTemplate>
    </>
  )
}

export default observer(InsightArtistPage)
